import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../Assets/style.css';
import profilepng from '../../Assets/Login-img/Component 1.png';
import { useEffect } from 'react';

import '../../Assets/All-Css/KYC/Kyc_Report.css';
import fontimg from '../../Assets/Login-img/addhaa-font.jpg';
import backimg from '../../Assets/Login-img/Aadhaar-back.png';
import panfont from '../../Assets/Login-img/Pan-card.png';
import addressproof from '../../Assets/Login-img/votercard.png'; 

import API from '../../api/Api';
import LoadingSpinner from '../../Componets/LoadingSpinner/LoadingSpinner';
import { toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import Pagination from '../../Componets/Pagination/Pagination';
import moment from 'moment';
import { SessionTimer } from '../../util/UtilMethod';
import { getAuthToken, getTokenDuration } from '../../util/Auth';
import TimeoutPop from '../../Componets/TimeoutPop/TimeoutPop';


const Kycverifyedreport = () => {
 	const navigate = useNavigate(); 

    // TOKEN EXPIRATION  
    const [timeoutPop, setTimeoutPop] = useState(false);        
    const token = getAuthToken();
    useEffect(() => {
      if(!token) { 
        navigate('/loginform');
        return; 
      }
      if (token === 'EXPIRED') {
        navigate('/loginform');
        return; 
      }
      const tokenDuration = getTokenDuration(); 
      setTimeout(() => {
        navigate('/loginform'); 
      }, tokenDuration);     // 1*60*60*1000   
    }, [token]);  
  
    useEffect(() => {
      const tokenDuration = getTokenDuration(); 
      const timer = setTimeout(() => {
        setTimeoutPop(true);
      }, (tokenDuration - 15*60*1000));
      return () => clearTimeout(timer);
    }, []);
    // TOKEN EXPIRATION END

  const [isViewFrontPopupOpen, setViewFrontPopupOpen] = useState(false);
  const [isViewBackPopupOpen, setViewBackPopupOpen] = useState(false); 
  const [isTaxDocPopupOpen, setTaxDocPopupOpen] = useState(false);
  const [isTaxDocBackPopupOpen, setTaxDocBackPopupOpen] = useState(false);
  const [isAddressPopupOpen, setAddressPopupOpen] = useState(false); 
  const [isRejectPopupOpen, setRejectPopupOpen] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');


  // Aadhaar font close
  const closeViewFrontPopup = () => {
    setViewFrontPopupOpen(false);
  };

  // Aadhaar back close
  const closeViewBackPopup = () => {
    setViewBackPopupOpen(false);
  };

  // Pan font close
  const closeTaxDocPopup = () => {
    setTaxDocPopupOpen(false);
  };

  // Pan back close
  const closeTaxDocBackPopup = () => {
    setTaxDocBackPopupOpen(false);
  };

  // Address close
  const closeAddressPopup = () => {
    setAddressPopupOpen(false);
  };

  // Rejection close
  const closeRejectionPopup = () => {
    setRejectPopupOpen(false);
  };


   // date filtter
  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const [sidebarHidden, setSidebarHidden] = useState(false);
  const [allDropdown, setAllDropdown] = useState([]);

  // State to manage session time
  const [sessionTime, setSessionTime] = useState(0);

  const nav = useNavigate ();

  useEffect(() => {
    setAllDropdown(document.querySelectorAll('#sidebar .side-dropdown'));
  }, []);


  // API DATA //////////////////////////////////////
  const sessionTimer = SessionTimer();
  const [loading, setLoading] = useState(false);
  const [listVerified, setListVerified] = useState([]);
  const [listNonVerified, setListNonVerified] = useState([]);
  const [singleVerified, setSingleVerified] = useState([]);

  const [showUserBlockPopup, setShowUserBlockPopup] = useState(false); 
  const [userID, setUserID] = useState(null);

  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10); 
  const [pagingCounter, setPagingCounter] = useState(1);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [search, setSearch] = useState(null);
  const [searchData, setSearchData] = useState(null);

  const [userType, setUserType] = useState(true);
  const [filterStatus, setFilterStatus] = useState('APPROVED,REJECTED,SPAM'); 

  const [pagePermission, setPagePermission] = useState([]); 
  const pagePermissionMethod = () => { 
    API.get("/staff/account/role/allow-pages").then((res) => {
      setPagePermission(res.data.data); 
    }).catch((err) => { 
    }).finally(() => {
    });  
  }

  const listKycVerifiedMethod = () => {
    setLoading(true);
    API.get("/staff/report/kyc/verified", {
      params: {
        page: currentPage+1,
        limit: itemsPerPage,
        fromDate: fromDate,
        toDate: toDate,
        search: searchData,
        status: filterStatus
      }
    }).then((res) => { 
      setListVerified(res.data.data.docs); 
      setTotalRecords(res.data.data.totalDocs); 
      setPagingCounter(res.data.data.pagingCounter);
    }).catch((err) => {
      // toast.error("Something went wrong.", {position:"top-center"}); 
    }).finally(() => {
      setLoading(false);
    });  
  }

  const listKycNonVerifiedMethod = () => {
    setLoading(true);
    API.get("/staff/report/kyc/unverified", {
      params: {
        page: currentPage+1,
        limit: itemsPerPage,
        fromDate: fromDate,
        toDate: toDate,
        search: searchData
      }
    }).then((res) => { 
      setListNonVerified(res.data.data.docs); 
      setTotalRecords(res.data.data.totalDocs); 
      setPagingCounter(res.data.data.pagingCounter);
    }).catch((err) => {
      // toast.error("Something went wrong.", {position:"top-center"}); 
    }).finally(() => {
      setLoading(false);
    });  
  }

  const handlePageChange = ({ selected }) => { 
    setCurrentPage(selected);
  };
  const pageCount = Math.ceil(totalRecords / itemsPerPage);

  const onClickApplyFilter = () => {
    setFromDate(startDate);
    setToDate(endDate); 
  }

  function inputSearchHandler(event) {
    setSearch(event.target.value);  
  }
  const onSearchClicked = () => {
    setSearchData(search);
  }

  const onFilterStatusChange = (event) => {
    setFilterStatus(event.target.value); 
  }


  // KYC BLOCK
  const finalUserBlockMethod = () => {
    const body = {
      id: userID,
      blockCause: 'KYC'
    }
    API.put("/staff/user/delete/temporary", body).then((res) => {
      listKycVerifiedMethod();
      setUserID(null);
      setShowUserBlockPopup(false);
      toast.success(res.data.message, {position:"top-center"});
    }).catch((err) => {
      toast.error(err.response.data.message, {position:"top-center"}); 
    });
  }


  useEffect(() => {
    pagePermissionMethod(); 
  }, []);

  useEffect(() => {
    listKycVerifiedMethod();
  }, [currentPage, fromDate, toDate, searchData, filterStatus, userType]);  

  useEffect(() => {
    listKycNonVerifiedMethod();
  }, [currentPage, fromDate, toDate, searchData, userType]);  


  useEffect(() => {
    // Attach event listeners for dropdown elements
    allDropdown.forEach((dropdown) => {
      const parentLink = dropdown.closest('li');
      parentLink.addEventListener('click', (e) => handleMenuClick(e, dropdown));
    });

    // Set an interval to update the session time every second
    const timerInterval = setInterval(() => {
      setSessionTime((prevTime) => prevTime + 1);
    }, 1000);

    // Clean up the interval on component unmount
    return () => {
      clearInterval(timerInterval);

      // Remove event listeners
      allDropdown.forEach((dropdown) => {
        const parentLink = dropdown.closest('li');
        parentLink.removeEventListener('click', (e) => handleMenuClick(e, dropdown));
      });
    };
  }, [allDropdown]);

  const handleDropdownClick = (e, dropdown) => {
    e.preventDefault();

    const isDropdownActive = e.currentTarget.classList.contains('active');

    // Close all dropdowns
    allDropdown.forEach((item) => {
      const aLink = item.parentElement.querySelector('a:first-child');
      aLink.classList.remove('active');
      item.classList.remove('show');
    });

    // Toggle the clicked dropdown
    e.currentTarget.classList.toggle('active', !isDropdownActive);
    dropdown.classList.toggle('show', !isDropdownActive);
  };

  const handleToggleSidebar = () => {
    setSidebarHidden((prevSidebarHidden) => !prevSidebarHidden);
  };

  const handleProfileClick = () => {
    const profileLink = document.querySelector('.profile-link');
    profileLink.classList.toggle('show');
  };

  const handleMenuClick = (e, dropdown) => {
    e.preventDefault();
    dropdown.classList.toggle('show');
  };

  // Convert seconds to HH:MM:SS format
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const pad = (num) => (num < 10 ? '0' + num : num);

    return `${pad(hours)}:${pad(minutes)}:${pad(remainingSeconds)}`;
  };


return (
<>
<section id="sidebar" className={sidebarHidden ? 'hide' : ''}> 
    <a href="#" className="brand">
      <img src={profilepng} alt="Profile Image" className="profile-image" />
      Support
    </a> 

    <ul className="side-menu">
      {pagePermission.some(el => el === 'dashboard') && 
      <li>
        <a href='#' className="active" onClick={()=>{navigate('/dashboard')}} >
          <i className="fas fa-tachometer-alt icon"></i> Dashboard
        </a>
      </li>}

      <li className="divider" data-text="main">
        Main
      </li>

       {/* --@@@@@@@@@@@@@@@@@@@@@@@@---Manage Request----@@@@@@@@@@@@@@@@@@@@@@@@@@*/}

      <li>
        {pagePermission.some(el => el === 'ManageRequests') && 
        <a href="#" >
          <i className="fas fa-folder icon fa-fw"></i> Manage Requests
          <i className="fas fa-chevron-right icon-right"></i>
        </a>}

        <ul className="side-dropdown">
          {pagePermission.some(el => el === 'kycverification') && 
          <li>
            <a href='#' onClick={()=>{navigate('/kycverification')}}>
              <i className="fas fa-id-card"></i> KYC Verification
            </a>
          </li>}

          {pagePermission.some(el => el === 'traderverification') && 
          <li>
            <a href='#' onClick={()=>{navigate('/traderverification')}}>
              <i className="fas fa-briefcase"></i> Trader Verification
            </a>
          </li>}

          {pagePermission.some(el => el === 'recharge') && 
          <li>
            <a href="#" onClick={()=>{navigate('/recharge')}}>
              <i className="fas fa-phone"></i> Recharge Request
            </a> 
          </li>}

          {pagePermission.some(el => el === 'c2c') && 
          <li>
            <a href="#" onClick={()=>{navigate('/c2c')}}>
              <i className="fas fa-exchange-alt"></i> C2C Request
            </a>
          </li>}

          {pagePermission.some(el => el === 'disputesattlement') && 
          <li>
            <a href="#" onClick={()=>{navigate('/disputesattlement')}}> 
              <i className="fas fa-ban"></i> Dispute Settlement
            </a>
          </li>}
        </ul>
      </li>

      {pagePermission.some(el => el === 'investotp') &&
      <li>
        <a href='#' onClick={()=>{navigate('/investotp')}}>
          <i className="fas fa-fingerprint icon fa-fw"></i>Invest Via OTP
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {/* --@@@@@@@@@@@@@@@@@@@@@@@@---View Reports----@@@@@@@@@@@@@@@@@@@@@@@@@@*/}

     
      <li>
        {pagePermission.some(el => el === 'ViewReports') && 
        <a href="#" >
          <i className="fas fa-file-alt icon fa-fw"></i> View Reports
          <i className="fas fa-chevron-right icon-right"></i>
        </a>}

        <ul className="side-dropdown">
          {pagePermission.some(el => el === 'kycverifyedreport') && 
          <li>
            <a href='#' onClick={()=>{navigate('/kycverifyedreport')}}>
              <i className="fas fa-id-card"></i> KYC Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'traderreport') && 
          <li>
            <a href='#' onClick={()=>{navigate('/traderreport')}}>
              <i className="fas fa-briefcase"></i> Trader Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'rechargereport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/rechargereport')}}>
              <i className="fas fa-donate"></i> Recharge Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'c2creport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/c2creport')}}>
              <i className="fas fa-money-check-alt"></i> C2C Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'disputereport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/disputereport')}}>
              <i className="fas fa-handshake"></i> Dispute Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'utrreport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/utrreport')}}>
              <i className="fas fa-money-check"></i> UTR Status Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'purchaseReport') && 
          <li>
            <a href="#"onClick={()=>{navigate('/purchaseReport')}}>
              <i className="fas fa-gift"></i> Purchase Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'partnerreport') && 
          <li>
            <a href="#" onClick={()=>{navigate('/partnerreport')}}>
              <i className="fas fa-handshake"></i> Partner Report
            </a>
          </li>}

          {pagePermission.some(el => el === 'ViewReports') && 
          <li>
            <a href="#" onClick={()=>{navigate('/noninvestedreport')}}>
              <i className="fas fa-file-alt"></i> Non Invested Report
            </a>
          </li>}

        </ul>
      </li>


    {/* --@@@@@@@@@@@@@@@@@@@@@@@@---Send Reports----@@@@@@@@@@@@@@@@@@@@@@@@@@*/}

      <li>
        {pagePermission.some(el => el === 'SendReports') && 
        <a href="#" >
          <i className="fas fa-paper-plane icon fa-fw"></i> Send Reports
          <i className="fas fa-chevron-right icon-right"></i>
        </a>}

        <ul className="side-dropdown">
          {pagePermission.some(el => el === 'sendecharge') && 
          <li>
            <a href='#' onClick={()=>{navigate('/sendecharge')}}>
              <i className="fas fa-file-alt"></i> Coin2Cash
            </a>
          </li>}
        </ul>
      </li>

      {pagePermission.some(el => el === 'memberManage') &&
      <li>
        <a href="#" onClick={()=>{nav('/memberManage')}} >
          <i className="fas fa-users icon fa-fw"></i> Manage Members
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'blockuser') &&
      <li>
        <a href="#" onClick={()=>{nav('/blockuser')}} >
          <i className="fas fa-user-circle icon fa-fw"></i> Block User
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'enquirymanage') &&
      <li>
        <a href="#" onClick={()=>{navigate('/enquirymanage')}}>
          <i className="fas fa-briefcase icon fa-fw"></i> Manage Enquiry
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'update') &&
      <li>
        <a href="#" onClick={()=>{navigate('/update')}}>
          <i className="fas fa-tools icon fa-fw"></i> Update Manager
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}
      
      {pagePermission.some(el => el === 'ChatSupport') &&
      <li>
        <a href='#' onClick={()=>{navigate('/ChatSupport')}}>
          <i className="fas fa-comment-alt icon fa-fw"></i>Chat Support 
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'TicketSupport') &&
      <li>
        <a href='#' onClick={()=>{navigate('/TicketSupport')}}>
          <i className="fas fa-chalkboard-teacher icon fa-fw"></i>Ticket Support 
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      {pagePermission.some(el => el === 'feedback') &&
      <li>
        <a href='#' onClick={()=>{navigate('/feedback')}}> 
          <i className="fas fa-comment-medical icon fa-fw"></i>Feedbacks
          <i className="fas fa-arrow-circle-right icon-right"></i>
        </a>
      </li>}

      <li className="divider" data-text="Exit">
        Exit
      </li>

      <div className="ads">
        <button className="Btn" onClick={()=>{navigate('/loginform')}}>
          <div className="sign">
            <svg viewBox="0 0 512 512">
              <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path>
            </svg>
          </div>
          <div className="text">Logout</div>
        </button>
      </div>
    </ul> 
  </section> 


{/* MAIN CONTENT */}
  <section id="content">
    <nav>
      <i className="bx bx-menu toggle-sidebar" onClick={handleToggleSidebar}></i>
      <form action="#">
        <div className="form-group">
          <span>Session Time: {formatTime(sessionTimer)}</span>
        </div>
      </form>
       
      <div className='notify'>
       <i className="fas fa-bell"></i>
     </div>


      <span className="divider"></span>
{/* --@@@@@@@@@@@@@@@@@@@@@@@@---Profile----@@@@@@@@@@@@@@@@@@@@@@@@@@*/} 
      <div className="profile">
        <img
          src="https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG-File.png"
          onClick={handleProfileClick}
          alt="Profile"
        />
        <ul className="profile-link">
          <li>
            <a href="" onClick={()=>{navigate('/selfprofile')}}>
              <i className="bx bxs-user-circle icon"></i> Profile
            </a>
          </li>
 {/* --@@@@@@@@@@@@@@@@@@@@@@@@---Logout----@@@@@@@@@@@@@@@@@@@@@@@@@@*/} 

          <li>
            <a href="#" onClick={()=>{navigate('/loginform')}}>
              <i className="bx bxs-log-out-circle"></i> Logout
            </a>
          </li>
        </ul>
      </div>
    </nav>

        <main>
          {/* {props.passedComponent} */}
 
          <section className="kyc-rep-Management-container">

{/* @@@@@@@@@@@@@@@@@@@@_____top Heading____@@@@@@@@@@@@@@@@@@@@@ */}

    <div className="kyc-rep-mange-top-heading">
      <h1><i className='bx bx-link'></i>Kyc Verification  Report</h1>
    </div>


{/* @@@@@@@@@@@@@@@@@@@@_____top filler search, back btn, date____@@@@@@@@@@@@@@@@@@@@@ */}
<div className="Purchase-rep-Date-Filter">
    <div className='radio-div1'>
        <input type="radio" defaultChecked  className='radio-inn' name='members' value='1' onClick={()=> {setCurrentPage(0); setUserType(true);}}/>
        <label htmlFor="name" className='mem-title'>Submitted Kyc Report</label>

        <input type="radio" className='radio-inn'  name='members' value='2' onClick={()=> {setCurrentPage(0); setUserType(false)}}/>
        <label htmlFor="name" className='mem-title'>Non-Submitted Kyc Report</label>
    </div>

    {userType === true &&
    <div>
      <label>Filter by Status: </label>
      <select 
        name="filter"
        style={{border:'1px solid #2b3cbb', borderRadius:'8px', width:'180px', fontSize:'16px', padding:'4px 10px', outline:'none'}}
        value={filterStatus}
        onChange={onFilterStatusChange}
      >
        <option value='APPROVED,REJECTED,SPAM'>Select Status</option>
        <option value={'APPROVED'}>Approved</option> 
        <option value={'REJECTED'}>Rejected</option> 
        <option value={'SPAM'}>Spam</option> 
      </select>
    </div>}

</div>

 {/* Back Button */}
    <div className="kyc-rep-mange-Fillter-bar">
      
    <button className="kyc-rep-back-btn" onClick={()=>{navigate('/kycverification')}} >
      <svg height="16" width="16" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 1024 1024"><path d="M874.690416 495.52477c0 11.2973-9.168824 20.466124-20.466124 20.466124l-604.773963 0 188.083679 188.083679c7.992021 7.992021 7.992021 20.947078 0 28.939099-4.001127 3.990894-9.240455 5.996574-14.46955 5.996574-5.239328 0-10.478655-1.995447-14.479783-5.996574l-223.00912-223.00912c-3.837398-3.837398-5.996574-9.046027-5.996574-14.46955 0-5.433756 2.159176-10.632151 5.996574-14.46955l223.019353-223.029586c7.992021-7.992021 20.957311-7.992021 28.949332 0 7.992021 8.002254 7.992021 20.957311 0 28.949332l-188.073446 188.073446 604.753497 0C865.521592 475.058646 874.690416 484.217237 874.690416 495.52477z"></path></svg>
      <span>Back</span>
  </button>



{/* Date fillter */}
<div className="kyc-rep-Date-Filter">
   <label htmlFor="start-date">From Date:</label>
   <input 
    type="date" 
    id="start-date" 
    value={moment(startDate).format('YYYY-MM-DD')}
    onChange={handleStartDateChange} 
  />

   <label htmlFor="end-date">To Date:</label>
   <input 
    type="date" 
    id="end-date" 
    value={moment(endDate).format('YYYY-MM-DD')}
    onChange={handleEndDateChange} 
  />

     <button className="Filter-Button" type='button' onClick={()=> {onClickApplyFilter()}}>
      Apply Filter
   </button>
</div>



 {/* Search Bar */}
<form className="kyc-rep-Search">
    <button type='button' onClick={() => {onSearchClicked()}}>
      <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-labelledby="search">
        <path d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9" stroke="currentColor" strokeWidth="1.333" strokeLinecap="round" strokeLinejoin="round"></path>
      </svg>
    </button>

    <input 
      className="serach-input" 
      placeholder="Search Member ID/Email ID/ Mobile No."  
      required 
      type="text" 
      value={search}
      onChange={inputSearchHandler}
      onKeyDown={onSearchClicked}
    />

    <button className="reset" type="button" onClick={()=>setSearch('')}>
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
      </svg>
    </button>
  </form>   
   
  </div>

  {userType === true &&
    <>
    <table>
      <thead>
        <tr>
          <th>Sl No.</th>
          <th>Member ID </th>
          <th>Country</th>
          <th>ID Title</th>
          <th>ID Number</th>
          <th>ID Front </th>
          <th>ID Back</th>
          <th>Tax Doc.Title</th>
          <th>Tax Doc.Number</th>
          <th>Tax Doc.Front</th>
          <th>Tax Doc.Back</th>
          <th>Address Proof</th>
          <th>Status</th>
          <th>Reason</th>
        </tr>
      </thead>

      <tbody>
      {loading && <LoadingSpinner />}
      {listVerified.map((data, index) => {
      return(
        <tr key={index}>
          <td>{index+pagingCounter}</td>
          <td>{data.referralCode}</td>
          <td>{data.country}</td>
          <td>Aadhaar Card</td>
          <td>{data.kyc.documentNumber}</td> 
          <td>
            <button className="kyc-rep-btn-popup view-front-btn" onClick={()=>{setViewFrontPopupOpen(true); setSingleVerified(data);}}>View</button>
          </td>
          <td>
            <button className="kyc-rep-btn-popup view-back-btn" onClick={()=>{setViewBackPopupOpen(true); setSingleVerified(data);}}>View</button>
          </td>
          <td>PAN Card</td>
          <td>{data.kyc.panDocumentNumber}</td>
          <td>
            <button className="kyc-rep-btn-popup tax-doc-btn" onClick={()=>{setTaxDocPopupOpen(true); setSingleVerified(data);}}>View</button>
          </td>
          <td>
            <button className="kyc-rep-btn-popup tax-back-btn" onClick={()=>{setTaxDocBackPopupOpen(true); setSingleVerified(data);}}>View</button>
          </td>
          <td>
            <button className="kyc-rep-btn-popup address-btn" onClick={()=>{setAddressPopupOpen(true); setSingleVerified(data);}}>View</button>
          </td>

          <td>
            <h1 
              className={`${data.kyc.status === "APPROVED" ? 'Approved' : 'reject'}`}
            >
              {data.kyc?.status === "APPROVED" && 'Approved'}
              {data.kyc?.status === "REJECTED" && 'Rejected'}
              {data.kyc?.status === "PENDING" && 'Pending'}
            </h1>
          </td>
          
          <td>
            {data.kyc.status === "REJECTED" &&
              <button className="kyc-rep-btn-popup reason-btn" onClick={()=>{setRejectPopupOpen(true); setSingleVerified(data);}}>View</button>
            }

            { data.kyc.status === "APPROVED" && <i class="fas fa-check-circle"></i> }
          </td>
        </tr>
      )})}
      </tbody>

    </table>
    <Pagination 
      pageCount={pageCount} 
      onPageChange={handlePageChange}     
    />
    </>}


    {/* NON KYC TABLE  */}
    {userType === false &&
    <>
    <table>
      <thead>
        <tr>
          <th>Sl No.</th>
          <th>Member ID </th>
          <th>Country</th>
          <th>Name</th>
          <th>Email</th>
          <th>Mobile</th>
          <th>Kyc Status</th>
          <th>Activation Counter</th>
          <th>Action</th>
        </tr>
      </thead>

      <tbody>
      {loading && <LoadingSpinner />}
      {listNonVerified.map((data, index) => {
      return(
        <tr key={index}>
          <td>{index+pagingCounter}</td>
          <td>{data.referralCode}</td>
          <td>{data.country}</td>
          <td>{data.name}</td>
          <td>{data.email}</td>
          <td>{data.mobile}</td>

          <td>
            <h1 
              className={`${data.kyc?.status === "APPROVED" ? 'Approved' : 'reject'}`}
              style={{fontSize:'16px'}}
            >
              {data.kyc?.status === "APPROVED" && 'Approved'}
              {data.kyc?.status === "REJECTED" && 'Rejected'}
              {data.kyc?.status === "PENDING" && 'Pending'}
            </h1>
          </td>

          <td
            style={{fontWeight:'800', color: !data.createdSince ? '#DC2626' : '#36395a'}}
          >
            {!data.createdSince ? 'In-Active' : `${data.createdSince?.dayCount} Days`}
          </td>

          <td>
            {pagePermission.some(el => el === 'userinfoBtnBlockKyc') &&
              <button 
                style={{backgroundColor: '#DC2626', color: '#fff', padding: '5px 8px', border: 'none', cursor: 'pointer', borderRadius: '5px'}} 
                onClick={()=>{setShowUserBlockPopup(true); setUserID(data._id);}}
              >Block</button>}
          </td>
        </tr>
      )})}
      </tbody>

    </table>
    <Pagination 
      pageCount={pageCount} 
      onPageChange={handlePageChange}     
    />
    </>}


    {/*----##################-------- All POPUPS HERE -----------################--*/} 

{/*--@@@@@@@@@@@@@@ Aadhaar font view @@@@@@@@@--*/}
    {isViewFrontPopupOpen && (
 <>
  <div className="kyc-rep-overlay"></div>
  <div className="kyc-rep-popup">
    <div className="kyc-rep-popup-content">
      <h2>ID Front</h2>

      <div className='kycImgDiv'>
        <img src={singleVerified.kyc.documentImage} alt="View Front Image" width="300" height="200" />
      </div>

      <button className="kyc-rep-close-btn" onClick={closeViewFrontPopup}>
        <i className="fas fa-times"></i>
      </button>
     
    </div>
    <button className="kyc-rep-done-btn" onClick={closeViewFrontPopup}>
        Done
      </button>
  </div>
</>
)}


{/*@@@@@@@@@@@@@ Aadhaar back view @@@@@@@@@@@-*/}

{isViewBackPopupOpen && (
 <>
  <div className="kyc-rep-overlay"></div>
  <div className="kyc-rep-popup">
    <div className="kyc-rep-popup-content">
      <h2>ID Back</h2>

      <div className='kycImgDiv'>
        <img src={singleVerified.kyc.documentImageBack} alt="View Back Image" width="300" height="200" />
      </div>

      <button className="kyc-rep-close-btn" onClick={closeViewBackPopup}>
        <i className="fas fa-times"></i>
      </button>
     
    </div>
    <button className="kyc-rep-done-btn" onClick={closeViewBackPopup}>
        Done
      </button>
  </div>
</>
)}


{/*--@@@@@@@@@@@@@@ Pan-card font view @@@@@@@@@--*/}
{isTaxDocPopupOpen && (
 <>
  <div className="kyc-rep-overlay"></div>
  <div className="kyc-rep-popup">
    <div className="kyc-rep-popup-content">
      <h2>Tax Doc Front</h2>

      <div className='kycImgDiv'>
        <img src={singleVerified.kyc.panDocumentName} alt="View Front Image" width="300" height="200" />
      </div>

      <button className="kyc-rep-close-btn" onClick={closeTaxDocPopup}>
        <i className="fas fa-times"></i>
      </button>
     
    </div>
    <button className="kyc-rep-done-btn" onClick={closeTaxDocPopup}>
        Done
      </button>
  </div>
</>
)}


{/*--@@@@@@@@@@@@@@ Pan-card back view @@@@@@@@@--*/}
{isTaxDocBackPopupOpen && (
 <>
  <div className="kyc-rep-overlay"></div>
  <div className="kyc-rep-popup">
    <div className="kyc-rep-popup-content">
      <h2>Tax Doc Back</h2>

      <div className='kycImgDiv'>
        <img src={singleVerified.kyc.panDocumentImageBack}  alt="View Back Image" width="300" height="200" />
      </div>

      <button className="kyc-rep-close-btn" onClick={closeTaxDocBackPopup}>
        <i className="fas fa-times"></i>
      </button>
     
    </div>
    <button className="kyc-rep-done-btn" onClick={closeTaxDocBackPopup}>
        Done
      </button>
  </div>
</>
)}


{/*--@@@@@@@@@@@@@@ Address view @@@@@@@@@--*/}
{isAddressPopupOpen && (
 <>
  <div className="kyc-rep-overlay"></div>
  <div className="kyc-rep-popup">
    <div className="kyc-rep-popup-content">
      <h2>Address Proof</h2>

      <div className='kycImgDiv'> 
        <img src={singleVerified.kyc.addressProof} alt="View Front Image" width="300" height="200" />
      </div>

      <button className="kyc-rep-close-btn" onClick={closeAddressPopup}>
        <i className="fas fa-times"></i>
      </button>
     
    </div>
    <button className="kyc-rep-done-btn" onClick={closeAddressPopup}>
        Done
      </button>
  </div>
</>
)}



{/*--@@@@@@@@@@@@@@ Reject Reason @@@@@@@@@--*/}
{isRejectPopupOpen && (
<>
  <div className="kyc-rep-overlay"></div>
  <div className="kyc-rep-popup">
    <div className="kyc-rep-popup-content">
      <h2>Reason For Rejection</h2>
      <textarea
        className="rejection-reason-textarea"
        disabled
      >{singleVerified.kyc.note ? singleVerified.kyc.note : 'The reason is not mentioned.'}</textarea>

      <button className="kyc-rep-close-btn" onClick={closeRejectionPopup}>
        <i className="fas fa-times"></i>
      </button>
    </div>
    <button className="kyc-rep-done-btn" onClick={closeRejectionPopup}>
      Done
    </button>
  </div>
</>
)}


{/* NON KYC BLOCK POPUP CONFIRMATION */}
{showUserBlockPopup && (
    <>
      <div className="Update-overlay"></div>
      <div className="Update-popup">
        <div className="Update-popup-content">
          <div className='Update-poptop'>
            <h1>{"Confirmation"}</h1>
          </div>

            <div className='radio-div1' style={{marginTop: '25px'}}>
              <h2 style={{fontSize:'17px', marginLeft:'50px'}}>Are you sure you want to Block?</h2>
            </div>

            <button type='button' className="Update-close-btn" onClick={()=> {setUserID(null); setShowUserBlockPopup(false);}}>
              <i className="fas fa-times"></i>
            </button>
        </div>

          <button className="Update-done-btn" type='button' onClick={()=> finalUserBlockMethod()}>
            Block
          </button>
      </div>
    </>
  )}


  </section>
        </main>
      </section>

      {timeoutPop && <TimeoutPop
        setTimeoutPop={setTimeoutPop}
      />}

</> 
  
  );
};

export default Kycverifyedreport;
