// Import necessary libraries and styles
import React, { useState } from 'react';
import logopng from '../Assets/Login-img/Component 1.png';
import womantalking from '../Assets/Login-img/woman talking with chatbot.png';
import youngwoman from '../Assets/Login-img/young woman.png';
import '../Assets/Login.css';

import axios from "axios";
import { toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import { redirect, useSubmit, Navigate, useNavigate } from 'react-router-dom';

const LoginForm = () => {
  // const submit = useSubmit();
  const navigate = useNavigate();
  const [isVerified, setIsVerified] = useState(false);

  // https://vtcncoin.com
  // https://bc-exchange-backend.unistep.store

  var API = axios.create({
      baseURL: "https://vtcncoin.com", 
      timeout: 120000,
      headers: {
        "Content-Type": "application/json;charset=utf-8", 
        "Accept": "application/json",
		    "Access-Control-Allow-Origin": '*',
      }
  });

  // API CALL METHOD 
  const [myEmail, setMyEmail] = useState(null);
  const [myOtp, setMyOtp] = useState(null);
  const [isOtpSent, setIsOtpSent] = useState(false);

  function handleEmail(event) {
    setMyEmail(event.target.value); 
  }
  function handleOtp(event) {
    setMyOtp(event.target.value);  
  }

  function getOtpMethod() {
    const body = {
      email: myEmail,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    API.post("/staff/auth/otp/login", body)
    .then((res) => {
        toast.success(res.data.message, {position:"top-center"});
        setIsOtpSent(true);  
    }).catch((err) => {
        toast.error(err.response.data.message, {position:"top-center"});
    }); 
  }

  function loginFormSubmit() {
    const body = {
      email: myEmail,
      otp: myOtp
    };

	API.post("/staff/auth/login", body)
    .then((res) => {
      toast.success(res.data.message, {position:"top-center"});
      setIsOtpSent(false); 

      // ACCESS TOKEN
      const accessToken = res.data.data;
      localStorage.setItem('accessToken', accessToken); 
      localStorage.setItem('loggedIn', true); 

      // TOKEN EXPIRATION
      const expiration = new Date();
      // expiration.setHours(expiration.getHours() + 1);
      expiration.setMinutes(expiration.getMinutes() + 70);         
      localStorage.setItem('expiration', expiration.toISOString());  

      // REDIRECT
      localStorage.setItem('reloadCount', 1); 
      return navigate("/dashboard"); 

    }).catch((err) => {
      toast.error(err.response.data.message, {position:"top-center"});  
  }); 


    // NO API CALL AUTH ////////////////////////////////
    // setIsOtpSent(false); 
    // const accessToken = "eyJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2NWQ4MjMyMmQxOGRmOWYzMTI2YzRiNjAiLCJuYW1lIjoiU3VyYWogU3RhZmYiLCJmcm9tVXNlciI6bnVsbCwicm9sZSI6IlNUQUZGIiwiZW1haWwiOiJzdXJhanVyYW53QGdtYWlsLmNvbSIsIm1vYmlsZSI6IjQwMDAwMDAwMTAiLCJyZWZlcnJhbENvZGUiOiJTVFNWMjYwNTAzNTkiLCJyaWdodENoaWxkIjpudWxsLCJsZWZ0Q2hpbGQiOm51bGwsImxldmVsIjowLCJzdG9ja0JhbGFuY2UiOjIyMCwidG90YWxJbmNvbWUiOjAsImlzVHJ4UGFzc0NyZWF0ZWQiOmZhbHNlLCJ0b3RhbFJlZmZlcmFsIjowLCJhbGxvd1JlZmVycmFsIjp0cnVlLCJlbWFpbE90cCI6IjM0NDg2MiIsImlzRW1haWxWZXJpZmllZCI6ZmFsc2UsImlzTW9iaWxlVmVyaWZpZWQiOmZhbHNlLCJpc0RlbGV0ZWQiOmZhbHNlLCJmY21Ub2tlbiI6bnVsbCwidHlwZSI6Ik5PUk1BTCIsInBhcnRuZXJSZWZlckNvZGUiOm51bGwsImZyb21QYXJ0bmVyUmVmZXJDb2RlIjpudWxsLCJ0ZXN0VXNlciI6ZmFsc2UsImNyZWF0ZWRBdCI6IjIwMjQtMDItMjNUMDQ6NDY6MjYuMzA5WiIsInVwZGF0ZWRBdCI6IjIwMjQtMDUtMDhUMDk6MTQ6NDIuNzI4WiJ9.mDRmfXf6EPxZHnoWga4Q8-yYprxK4GSggDNvqI_Knt4";
    // localStorage.setItem('accessToken', accessToken);  
    // localStorage.setItem('loggedIn', true); 

    // const expiration = new Date();
    // expiration.setHours(expiration.getHours() + 5); 
    // localStorage.setItem('expiration', expiration.toISOString()); 

    // localStorage.setItem('reloadCount', 1); 
    // return navigate("/dashboard"); 
  }


  return (
    <div className="login-container">
      <a href="#" className="site-link">
        BC Exchange.org
      </a>
      <div className="left-container">
        <svg
          className="rectangle-422"
          width="576"
          height="902"
          viewBox="0 0 576 902"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 0L0 902H274.019C257.011 660.122 511.755 120.691 274.019 0L0 0Z" fill="#153362" />
        </svg>
        <div className="left-img">
          <img src={womantalking} alt="woman talking with chatbot" />
        </div>
      </div>

      <div className="login-form">
        <img className="logo" src={logopng} alt="Logo" />
        <h1>Support Login</h1>

        {isVerified ? (
          // Render login form after successful verification
          <>
            <div className="input-container">
              <i className="fas fa-envelope"></i>
              <input
                type="text"
                id="username"
                name="username"
                placeholder="abc123@gmail.com"
                disabled
              />
            </div>
            <p className="subtitle">Logged in successfully!</p>
          </>
        ) : (
          // Render verification form
          <>
            <div className="input-container login-email-icon">
              <i className="fas fa-envelope"></i>
              <input
                type="email"
                id="username"
                placeholder="Email ID"
                name="email"
                onChange={handleEmail}
                readOnly={isOtpSent ? true : false}
              />
            </div>
            <p className="subtitle">
              {isOtpSent
                ? 'Enter the OTP sent to your email.'
                : 'OTP will be sent to your registered email address.'}
            </p>

            {isOtpSent && (
              <input
                type="number"
                id="otp"
                placeholder="Enter OTP"
                name="otp"
                onChange={handleOtp} 
              />
            )}

            <button
              type='button'
              className="verify-button mylogin-btn" 
              onClick={()=> getOtpMethod()}
            >
              {isOtpSent ? 'Resend OTP' : 'Verify'}
            </button>

            {isOtpSent && (
              <button
                type='button'
                className="verify-button mylogin-btn" 
                onClick={()=> loginFormSubmit()}
              >Login</button>
            )}
          </>
        )}
      </div>

      <div className="right-container">
        <svg
          className="rectangle-423"
          width="383"
          height="902"
          viewBox="0 0 383 902"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M383 902L383 0H103.88C121.204 241.878 -138.283 781.309 103.88 902H383Z"
            fill="url(#paint0_linear_5202_14184)"
          />
          <path
            d="M383 902L383 0H103.88C121.204 241.878 -138.283 781.309 103.88 902H383Z"
            fill="url(#paint1_linear_5202_14184)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_5202_14184"
              x1="383"
              y1="902"
              x2="89.7686"
              y2="582.519"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#0075FF" stopOpacity="0" />
              <stop offset="0.98123" stopColor="white" stopOpacity="0" />
              <stop offset="1" stopColor="#0075FF" stopOpacity="0.932292" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_5202_14184"
              x1="383"
              y1="902"
              x2="89.7686"
              y2="582.519"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#0075FF" stopOpacity="0" />
              <stop offset="0.98123" stopColor="white" stopOpacity="0" />
              <stop offset="1" stopColor="#153362" stopOpacity="0.932292" />
            </linearGradient>
          </defs>
        </svg>
        
        <div className="right-img">
          <img src={youngwoman} alt="young woman" />
        </div>
     
      </div>
    </div>
  );
};

export default LoginForm;
