import { io } from 'socket.io-client';
import { getAuthToken } from '../util/Auth';

const accessToken = getAuthToken(); 

// https://vtcncoin.com
// https://bc-exchange-backend.unistep.store

const SocketIO = io("https://vtcncoin.com/staff", {
    extraHeaders: {
        "Authorization": accessToken,
        "Content-Type": "application/json;charset=utf-8", 
        "Accept": "application/json",
	    "Access-Control-Allow-Origin": '*',
    }
});

export default SocketIO; 